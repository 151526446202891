import { computed, reactive } from 'vue'
import { defineStore } from 'pinia'

import { fetchWrapper } from '@/api/fetchApi'
import { kernGeometry } from '@/utils/consts/geometries'
import { GeometryMapLayers, RoadObjectsTypeId } from '@/utils/lists/lists'
import { roadObjectOpacity } from '@/assets/data/road-object-opacity'

import type { RoadState } from '@/types/Road'
import type { MapRenderParams, ObjectType } from '@/types/map/Map'
import type {
  OrtoInfo,
  RenderLayer,
  RoadExpluatatuion,
  RoadGeoJSON,
  RoadGeoJSONResponse,
  RoadGeometry,
  RoadResponse
} from '@/types/Road'
import type { SourceSpecification } from 'maplibre-gl'

export const useRoadStore = defineStore('road', () => {
  const state: RoadState = reactive({
    roadSign: undefined,
    road: undefined,
    bbox: undefined,
    roadAxis: undefined,
    roadway: undefined,
    landLots: undefined,
    kilometerPol: undefined,
    sideWalks: undefined,
    light: undefined,
    realty: undefined,
    edges: undefined,
    bridges: undefined,
    curves: undefined,
    climatic: undefined,
    borders: undefined,
    semaphores: undefined,
    bumps: undefined,
    weightControl: undefined,
    crosswalks: undefined,
    roadMarks: undefined,
    roadExit: undefined,
    stakeSegments: undefined,
    roadInfoIsOpen: false,
    ortos: undefined,
    idSP: undefined,
    expluatationCategory: undefined,
    railwayCrossing: undefined,
    roadFenceSegments: undefined,
    railwaySegments: undefined,
    lightingPoles: undefined,
    roadFortifications: undefined,
    kernData: undefined,
    roadLandscaping: undefined,
    adjacentLands: undefined,
    trumLine: undefined,
    stormDrainWell: undefined,
    waterDisposal: undefined,
    busStop: undefined,
    square: undefined,
    roadObjects: {
      __fetching: false,
      __success: false,
      __error: false
    },
    rating: 0,
    renderData: [
      {
        id: GeometryMapLayers.ROAD_LANDSCAPING,
        data: computed(() => state.roadLandscaping),
        params: {
          showed: true,
          name: GeometryMapLayers.ROAD_LANDSCAPING,
          label: 'Озеленение дороги',
          color: '#50C878',
          hoveredColor: '#5ce65c',
          activeColor: '#0f4d0f'
        }
      },
      {
        id: GeometryMapLayers.ADJACENT_LANDS,
        data: computed(() => state.adjacentLands),
        params: {
          showed: true,
          name: GeometryMapLayers.ADJACENT_LANDS,
          label: 'Прилегающие угодья',
          color: '#ffd32c',
          hoveredColor: '#ffea99',
          activeColor: '#e0bc00'
        }
      },
      {
        id: RoadObjectsTypeId.LAND_LOTS,
        data: computed(() => state.landLots),
        params: {
          showed: true,
          name: GeometryMapLayers.ROAD_LAND_LOTS,
          label: 'Земельные участки',
          color: '#d6ebcc',
          hoveredColor: '#c3e3b3',
          activeColor: '#c3e3b3',
          borderColor: '#c3e3b3'
        }
      },
      {
        id: RoadObjectsTypeId.SIDE_WALKS,
        data: computed(() => state.sideWalks),
        params: {
          showed: true,
          name: GeometryMapLayers.ROAD_SIDE_WALKS,
          label: 'Тротуары',
          color: '#c4a97c',
          hoveredColor: '#d4b685',
          activeColor: '#d4b685',
          opacity: roadObjectOpacity
        }
      },
      {
        id: RoadObjectsTypeId.ROAD_REALTY,
        data: computed(() => state.realty),
        params: {
          showed: true,
          name: GeometryMapLayers.ROAD_REALTY,
          label: 'Здания',
          color: 'rgba(116, 152, 201, 0.812)',
          hoveredColor: 'rgba(116, 152, 201, 1)',
          activeColor: 'rgba(116, 152, 201, 1)',
          opacity: roadObjectOpacity
        }
      },
      {
        id: RoadObjectsTypeId.ROADWAY,
        data: computed(() => state.roadway),
        params: {
          showed: true,
          name: GeometryMapLayers.ROAD_WAY,
          label: 'Покрытие дороги',
          color: '#807f8d',
          hoveredColor: '#b3b3b3',
          activeColor: '#b3b3b3'
        }
      },
      {
        id: RoadObjectsTypeId.ROAD_MARKS,
        data: computed(() => state.roadMarks),
        params: {
          showed: true,
          name: GeometryMapLayers.ROAD_MARKS,
          label: 'Разметка',
          color: 'white',
          hoveredColor: 'white',
          activeColor: 'white',
          opacity: roadObjectOpacity,
          lineWidth: 2
        }
      },
      {
        id: RoadObjectsTypeId.ROAD_LIGHT,
        data: computed(() => state.light),
        params: {
          showed: true,
          name: GeometryMapLayers.ROAD_LIGHT,
          label: 'Освещение',
          color: '#fcce00',
          hoveredColor: '#fcba03',
          activeColor: '#fcba03',
          lineWidth: ['interpolate', ['linear'], ['zoom'], 15, 4, 18, 12, 22, 12]
        }
      },
      {
        id: RoadObjectsTypeId.ROAD_EDGES,
        data: computed(() => state.edges),
        params: {
          showed: true,
          name: GeometryMapLayers.ROAD_EDGES,
          label: 'Ребра',
          color: '#D2B48C',
          hoveredColor: '#f4a460',
          activeColor: '#DEB887',
          opacity: roadObjectOpacity
        }
      },
      {
        id: RoadObjectsTypeId.BRIDGES,
        data: computed(() => state.bridges),
        params: {
          showed: true,
          name: GeometryMapLayers.ROAD_BRIDGES,
          label: 'Мосты',
          color: '#bdbdbd',
          hoveredColor: '#a3a3a3',
          activeColor: '#a3a3a3'
        }
      },
      {
        id: RoadObjectsTypeId.CLIMATIC_ZONE,
        data: computed(() => state.climatic),
        params: {
          showed: true,
          name: GeometryMapLayers.ROAD_CLIMATIC,
          label: 'Климатичские зоны',
          color: 'green',
          hoveredColor: 'orchid',
          activeColor: 'orchid'
        }
      },
      {
        id: RoadObjectsTypeId.WEIGHT_CONTROL,
        data: computed(() => state.weightControl),
        params: {
          showed: true,
          name: GeometryMapLayers.ROAD_WEIGHT_CONTROL,
          label: 'Весовой контроль',
          color: 'green',
          hoveredColor: '#3aba3e',
          activeColor: '#0e8711',
          opacity: roadObjectOpacity
        }
      },
      {
        id: RoadObjectsTypeId.ROAD_EXIT,
        data: computed(() => state.roadExit),
        params: {
          showed: true,
          name: GeometryMapLayers.ROAD_EXIT,
          label: 'Съезд с дороги',
          color: '#96918d',
          hoveredColor: '#b3b3b3',
          activeColor: '#b3b3b3',
          opacity: roadObjectOpacity
        }
      },
      {
        id: RoadObjectsTypeId.BORDERS,
        data: computed(() => state.borders),
        params: {
          showed: true,
          name: GeometryMapLayers.ROAD_BORDERS,
          label: 'Бордюр',
          color: '#7d7d7d',
          hoveredColor: '#aeb1b5',
          activeColor: '#74777a',
          lineWidth: 4,
          opacity: roadObjectOpacity
        }
      },
      {
        id: GeometryMapLayers.ROAD_AXIS,
        data: computed(() => state.roadAxis),
        params: {
          showed: true,
          name: GeometryMapLayers.ROAD_AXIS,
          label: 'Ось дороги',
          color: '#d6201d',
          hoveredColor: '#d6201d',
          activeColor: '#d6201d',
          lineWidth: ['interpolate', ['linear'], ['zoom'], 15, 3, 18, 6]
        }
      },
      {
        id: RoadObjectsTypeId.CURVES,
        data: computed(() => state.curves),
        params: {
          showed: true,
          name: GeometryMapLayers.ROAD_CURVES,
          label: 'Кривые',
          color: 'orange',
          hoveredColor: '#ab7003',
          activeColor: '#ffa500',
          lineWidth: ['interpolate', ['linear'], ['zoom'], 15, 3, 18, 6],
          opacity: roadObjectOpacity
        }
      },
      {
        id: RoadObjectsTypeId.ROAD_SIGN,
        data: computed(() => state.roadSign),
        params: {
          showed: true,
          name: GeometryMapLayers.ROAD_SIGN,
          label: 'Дорожные знаки',
          opacity: roadObjectOpacity
        }
      },
      {
        id: RoadObjectsTypeId.GUIDE_STAKE_SEGMENTS,
        data: computed(() => state.stakeSegments),
        params: {
          showed: true,
          name: GeometryMapLayers.ROAD_GUIDE_STAKE_SEGMENTS,
          label: 'Сигнальные столбики',
          color: '#cc4993',
          hoveredColor: '#852c5e',
          activeColor: '#852c5e',
          opacity: roadObjectOpacity
        }
      },
      {
        id: RoadObjectsTypeId.EXPLUATATION_CATEGORY,
        data: computed(() => state.expluatationCategory),
        params: {
          showed: true,
          name: GeometryMapLayers.EXPLUATATION_CATEGORY,
          label: 'Эксплуатационные категории',
          color: '#8236bc94',
          hoveredColor: '#6e00c2',
          activeColor: '#724d8f',
          lineWidth: 4
        }
      },
      {
        id: RoadObjectsTypeId.ROAD_FENCE_SEGMENTS,
        data: computed(() => state.roadFenceSegments),
        params: {
          showed: true,
          name: GeometryMapLayers.ROAD_FENCE_SEGMENTS,
          label: '',
          color: 'green',
          hoveredColor: 'rgba(146, 233, 114, 1)',
          activeColor: 'rgba(146, 233, 114, 1)',
          lineWidth: 4,
          opacity: roadObjectOpacity
        }
      },
      {
        id: RoadObjectsTypeId.RAIL_WAY_CROSSING,
        data: computed(() => state.railwayCrossing),
        params: {
          showed: true,
          name: GeometryMapLayers.RAILWAY_CROSSING,
          label: 'Железнодорожные переезды',
          color: 'brown',
          hoveredColor: '#825323',
          activeColor: '#9c4f00',
          opacity: roadObjectOpacity
        }
      },
      {
        id: RoadObjectsTypeId.LIGHTING_POLES,
        data: computed(() => state.lightingPoles),
        params: {
          showed: true,
          name: GeometryMapLayers.LIGHTING_POLES,
          label: 'Столбы освещения',
          color: '#fafaa0',
          hoveredColor: '#eded80',
          activeColor: '#eded80',
          opacity: roadObjectOpacity
        }
      },
      {
        id: RoadObjectsTypeId.ROAD_FORTIFICATIONS,
        data: computed(() => state.roadFortifications),
        params: {
          showed: true,
          name: GeometryMapLayers.ROAD_FORTIFICATIONS,
          label: 'Укрепления дороги',
          color: '#e8b480',
          hoveredColor: '#d6995c',
          activeColor: '#d6995c',
          opacity: roadObjectOpacity
        }
      },
      {
        id: GeometryMapLayers.KERN,
        data: computed(() => state.kernData),
        params: {
          showed: true,
          circleRadius: ['interpolate', ['exponential', 1], ['zoom'], 16.5, 5, 19.5, 10],
          name: GeometryMapLayers.KERN,
          label: 'Взятие пробы керна',
          color: 'green',
          hoveredColor: '#168f00',
          activeColor: '#23e200',
          zIndex: `${GeometryMapLayers.LIGHTING_POLES}-circle`
        }
      },
      {
        id: GeometryMapLayers.TRUM_LINE,
        data: computed(() => state.trumLine),
        params: {
          showed: true,
          name: GeometryMapLayers.TRUM_LINE,
          label: 'Трамвайная линия',
          color: '#808080',
          hoveredColor: '#a8a8a8',
          activeColor: '#575757',
          zIndex: `${GeometryMapLayers.LIGHTING_POLES}-circle`
        }
      },
      {
        id: GeometryMapLayers.STROM_DRAIN_WELL,
        data: computed(() => state.stormDrainWell),
        params: {
          showed: true,
          name: GeometryMapLayers.STROM_DRAIN_WELL,
          label: 'Колодец ливневой канализации',
          color: '#663c1f',
          hoveredColor: '#e08543',
          activeColor: '#895129'
        }
      },
      {
        id: GeometryMapLayers.WATER_DISPOSAL,
        data: computed(() => state.waterDisposal),
        params: {
          showed: true,
          name: GeometryMapLayers.WATER_DISPOSAL,
          label: 'Водоотведение',
          color: '#4052d6',
          hoveredColor: '#1591ea',
          activeColor: '#00008a'
        }
      },
      {
        id: GeometryMapLayers.BUS_STOP,
        data: computed(() => state.busStop),
        params: {
          showed: true,
          name: GeometryMapLayers.BUS_STOP,
          label: 'Остановка общественного трансрорта',
          color: '#9d00ff',
          hoveredColor: '#b069db',
          activeColor: '#6e00b3'
        }
      },
      {
        id: GeometryMapLayers.ROAD_BUMPS,
        data: computed(() => state.bumps),
        params: {
          showed: true,
          name: GeometryMapLayers.ROAD_BUMPS,
          label: 'Искусственные неровности',
          color: '#2F3C7E',
          hoveredColor: '#FBEAEB',
          activeColor: '#8AAAE5'
        }
      },
      {
        id: GeometryMapLayers.SQUARE,
        data: computed(() => state.square),
        params: {
          showed: true,
          name: GeometryMapLayers.SQUARE,
          label: 'Площадки',
          color: '#808080',
          hoveredColor: '#8d8d8d',
          activeColor: '#747474'
        }
      }
    ]
  })

  const roadway = computed(() => state.roadway)
  const roadAxis = computed(() => state.roadAxis)
  const bbox = computed(() => state.bbox)
  const roadSign = computed(() => state.roadSign)
  const getRoad = computed(() => state.road)
  const getGeoJSONLandLots = computed(() => state.landLots)
  const getKilometerPol = computed(() => state.kilometerPol)
  const sideWalks = computed(() => state.sideWalks)
  const light = computed(() => state.light)
  const realty = computed(() => state.realty)
  const edges = computed(() => state.edges)
  const bridges = computed(() => state.bridges)
  const curves = computed(() => state.curves)
  const climatic = computed(() => state.climatic)
  const borders = computed(() => state.borders)
  const semaphores = computed(() => state.semaphores)
  const bumps = computed(() => state.bumps)
  const weightControl = computed(() => state.weightControl)
  const crosswalks = computed(() => state.crosswalks)
  const roadMarks = computed(() => state.roadMarks)
  const roadExit = computed(() => state.roadExit)
  const roadBBox = computed(() => state.bbox)
  const ortos = computed(() => state.ortos)
  const idSP = computed(() => state.idSP)
  const stakeSegments = computed(() => state.stakeSegments)
  const expluatationCategory = computed(() => state.expluatationCategory)
  const roadFenceSegments = computed(() => state.roadFenceSegments)
  const railwayCrossing = computed(() => state.railwayCrossing)
  const lightingPoles = computed(() => state.lightingPoles)
  const roadFortifications = computed(() => state.roadFortifications)
  const roadObjects = computed(() => state.roadObjects)
  const rating = computed(() => state.rating)
  const square = computed(() => state.square)
  const renderData = computed(() => state.renderData)
  const hasRoadSign = computed(() => {
    const roadSign = state.renderData.find((el) => el.params.name === GeometryMapLayers.ROAD_SIGN)

    return !!roadSign?.data?.features?.length
  })

  function setRoad(item: RoadResponse | undefined) {
    state.road = item
  }

  function setGeoJSONRoad(item: SourceSpecification | undefined) {
    state.roadway = item
  }

  function setRenderData(items: MapRenderParams[]) {
    state.renderData = items
  }

  function setRoadAxis(item: RoadGeoJSON | undefined) {
    state.roadAxis = item
  }

  async function fetchRoad(idRoad: number) {
    if (!idRoad) return

    const infoRoad = (await fetchWrapper.get(`/roads/${idRoad}/`, {})) as RoadResponse
    setRoad(infoRoad)
  }

  async function fetchRoadAxis(idRoad: number) {
    const response = await Promise.all([
      fetchWrapper.get(`/roads/${idRoad}/geojson/`, {}),
      fetchWrapper.get(`/roads/${idRoad}/`, {})
    ])

    setRoadAxis((response[0] as RoadGeoJSONResponse).geometry)
    state.bbox = (response[0] as RoadGeoJSONResponse).bbox
    state.road = response[1] as RoadResponse
  }

  async function fetchRoadIDSPandOwner(roadId: number) {
    const response = (await fetchWrapper.get(
      `/roads/${roadId}/objects/${RoadObjectsTypeId.EXPLUATATION_CATEGORY}?page_number=1&elements_on_page=10`,
      {}
    )) as RoadExpluatatuion

    state.idSP = [
      ...new Set(
        response.data
          .filter((el) => el.data.exploitation_category_string)
          .map((el) => el.data.exploitation_category_string)
      )
    ].join(',')
  }

  async function fetchRoadWithGeoJSON(idRoad: number) {
    try {
      state.roadObjects.__fetching = true

      const responses: RenderLayer[] = await requestArr(idRoad)

      const [road, axis] = responses.slice(0, 2)
      const [roadSign] = responses.slice(responses.length - 1)

      const axisData = axis.data as RoadGeometry

      setRoad(road.data as RoadResponse)
      setRoadAxis(axisData.geometry)
      state.bbox = axisData.bbox
      state.roadSign = roadSign.data as RoadGeoJSON

      const roadObjects = responses.slice(2)
      roadObjects.splice(roadObjects.length - 1, 1)

      for (const response of roadObjects) {
        applyDataToStore(response.id, response.data as RoadGeoJSON)
      }

      state.kernData = idRoad === 11 ? kernGeometry : undefined

      state.roadObjects.__success = true
      state.roadObjects.__error = false
    } catch (e) {
      state.roadObjects.__success = false
      state.roadObjects.__error = true
      throw new Error(e)
    } finally {
      state.roadObjects.__fetching = false
    }
  }

  function applyDataToStore(id: string | number, response?: RoadGeoJSON) {
    switch (id) {
      case RoadObjectsTypeId.ROADWAY: {
        state.roadway = response
        break
      }
      case RoadObjectsTypeId.LAND_LOTS: {
        state.landLots = response
        break
      }
      case RoadObjectsTypeId.ROAD_MARKS: {
        state.roadMarks = response
        break
      }
      case RoadObjectsTypeId.SIDE_WALKS: {
        state.sideWalks = response
        break
      }
      case RoadObjectsTypeId.ROAD_REALTY: {
        state.realty = response
        break
      }
      case RoadObjectsTypeId.ROAD_LIGHT: {
        state.light = response
        break
      }
      case RoadObjectsTypeId.ROAD_EDGES: {
        state.edges = response
        break
      }
      case RoadObjectsTypeId.BRIDGES: {
        state.bridges = response
        break
      }
      case RoadObjectsTypeId.CURVES: {
        state.curves = response
        break
      }
      case RoadObjectsTypeId.CLIMATIC_ZONE: {
        state.climatic = response
        break
      }
      case RoadObjectsTypeId.BORDERS: {
        state.borders = response
        break
      }
      case RoadObjectsTypeId.BUMPS: {
        state.bumps = response
        break
      }
      case RoadObjectsTypeId.WEIGHT_CONTROL: {
        state.weightControl = response
        break
      }
      case RoadObjectsTypeId.ROAD_EXIT: {
        state.roadExit = response
        break
      }
      case RoadObjectsTypeId.GUIDE_STAKE_SEGMENTS: {
        state.stakeSegments = response
        break
      }
      case RoadObjectsTypeId.EXPLUATATION_CATEGORY: {
        state.expluatationCategory = response
        break
      }
      case RoadObjectsTypeId.ROAD_FENCE_SEGMENTS: {
        state.roadFenceSegments = response
        break
      }
      case RoadObjectsTypeId.RAIL_WAY_CROSSING: {
        state.railwayCrossing = response
        break
      }
      case RoadObjectsTypeId.LIGHTING_POLES: {
        state.lightingPoles = response
        break
      }
      case RoadObjectsTypeId.ROAD_FORTIFICATIONS: {
        state.roadFortifications = response
        break
      }
      case RoadObjectsTypeId.ROAD_LANDSCAPING: {
        state.roadLandscaping = response
        break
      }
      case RoadObjectsTypeId.ADJACENT_LANDS: {
        state.adjacentLands = response
        break
      }
      case RoadObjectsTypeId.TRUM_LINE: {
        state.trumLine = response
        break
      }
      case RoadObjectsTypeId.STROM_DRAIN_WELL: {
        state.stormDrainWell = response
        break
      }
      case RoadObjectsTypeId.WATER_DISPOSAL: {
        state.waterDisposal = response
        break
      }
      case RoadObjectsTypeId.BUS_STOP: {
        state.busStop = response
        break
      }
      case RoadObjectsTypeId.SQUARE: {
        state.square = response
        break
      }
      default: {
        break
      }
    }
  }

  function findRenderData(name: string, data: MapRenderParams[]) {
    return data.find((el) => el.params.name.startsWith(name))
  }

  async function fetchRoadGeometry(layerId: number) {
    const response = (await fetchWrapper.get(
      `/roads/${getRoad.value?.id}/layer/${layerId}`,
      {}
    )) as RoadGeoJSON

    applyDataToStore(layerId, response)
  }

  async function requestArr(idRoad: number) {
    const types = (await fetchWrapper.get(
      '/dicts/object-types/collection/?order=asc&order_by=id'
    )) as ObjectType[]

    const roadLayers = types.filter(({ id }) => {
      const exceptions = [
        RoadObjectsTypeId.ROAD_SIGN,
        RoadObjectsTypeId.SEMAPHORES,
        RoadObjectsTypeId.KILOMETER_POL,
        RoadObjectsTypeId.CROSSWALKS
      ]

      if (exceptions.includes(id)) return false

      return true
    })

    const layers: RenderLayer[] = await Promise.all(
      roadLayers.map(async ({ id }) => ({
        data: (await fetchWrapper.get(`/roads/${idRoad}/layer/${id}`)) as RoadGeoJSON,
        id
      }))
    )

    const arr: RenderLayer[] = [
      { data: (await fetchWrapper.get(`/roads/${idRoad}/`, {})) as RoadResponse, id: 'road' },
      {
        data: (await fetchWrapper.get(`/roads/${idRoad}/geojson`, {})) as RoadGeoJSON,
        id: 'road-axis'
      },
      ...layers,
      {
        data: (await fetchWrapper.post(`/roads/${idRoad}/layer/`, [
          RoadObjectsTypeId.ROAD_SIGN,
          RoadObjectsTypeId.KILOMETER_POL,
          RoadObjectsTypeId.SEMAPHORES
        ])) as RoadGeoJSON,
        id: 'road-sign'
      }
    ]

    return Promise.all(arr)
  }

  async function fetchMapDefects(idRoad: number) {
    const roadWay = (await fetchWrapper.get(
      `/roads/${idRoad}/layer/${RoadObjectsTypeId.ROADWAY}`,
      {}
    )) as SourceSpecification

    setGeoJSONRoad(roadWay)
  }

  function togglePreloader() {
    state.roadObjects.__fetching = !state.roadObjects.__fetching
  }

  async function fetchOrto(roadId: number) {
    const data = await fetchWrapper.get(`/roads/roads/${roadId}/tails/`, {})

    state.ortos = data as OrtoInfo[]
  }

  function setRating(rating: number) {
    state.rating = rating
  }

  function changeVisible(id: string, checked: boolean) {
    const current = renderData.value.filter((el) => el.params.name?.startsWith(id))

    current.forEach((el) => (el.params.showed = checked))
  }

  function resetVisible(data: MapRenderParams[]) {
    data.forEach((el) => (el.params.showed = true))
  }

  return {
    getRoad,
    roadway,
    roadAxis,
    roadSign,
    getGeoJSONLandLots,
    getKilometerPol,
    sideWalks,
    light,
    realty,
    edges,
    bridges,
    curves,
    climatic,
    borders,
    semaphores,
    bumps,
    weightControl,
    crosswalks,
    roadExit,
    bbox,
    roadMarks,
    roadBBox,
    ortos,
    idSP,
    roadObjects,
    rating,
    stakeSegments,
    expluatationCategory,
    roadFenceSegments,
    railwayCrossing,
    lightingPoles,
    roadFortifications,
    renderData,
    square,

    hasRoadSign,

    findRenderData,
    changeVisible,
    resetVisible,
    togglePreloader,

    setRoad,
    setGeoJSONRoad,
    setRoadAxis,
    setRating,
    setRenderData,

    fetchRoadWithGeoJSON,
    fetchRoadIDSPandOwner,
    fetchRoadAxis,
    fetchRoad,
    fetchOrto,
    fetchMapDefects,
    fetchRoadGeometry
  }
})
